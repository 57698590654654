/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Flexbox from "vux/src/components/flexbox/flexbox.vue";
import FlexboxItem from "vux/src/components/flexbox/flexbox-item.vue";
import ViewBox from "vux/src/components/view-box/index.vue";
import Timeline from "vux/src/components/timeline/timeline.vue";
import TimelineItem from "vux/src/components/timeline/timeline-item.vue";
import Tab from "vux/src/components/tab/tab.vue";
import TabItem from "vux/src/components/tab/tab-item.vue";
import Scroll from '@/components/Scroll/Scroll';
export default {
  name: "AttendDetail",
  components: {
    Flexbox: Flexbox,
    FlexboxItem: FlexboxItem,
    ViewBox: ViewBox,
    Timeline: Timeline,
    TimelineItem: TimelineItem,
    Tab: Tab,
    TabItem: TabItem,
    Scroll: Scroll
  },
  data: function data() {
    return {
      list: [],
      titles: [{
        title: "迟到",
        index: "1"
      }, {
        title: "早退",
        index: "4"
      }, {
        title: "请假",
        index: "2"
      }, {
        title: "旷工",
        index: "3"
      }],
      content: '',
      index: 0,
      pullDownRefresh: {
        threshold: 60,
        // 下拉多少开始刷新
        stop: 40 // 下拉回弹到多少停住

      },
      isPullDown: false,
      // 正在下拉刷新
      emptyShow: false
    };
  },
  methods: {
    // 下拉刷新
    onPullingDown: function onPullingDown() {
      if (this.isPullDown) return;
      this.isPullDown = true; // 下拉刷新

      this.list = [];
      this.getAttenceDetail(this.index, this.$route.params.date);
    },
    // 刷新 Scroll 状态
    updateScroll: function updateScroll() {
      this.isPullDown = false;
      this.$refs.scroll.forceUpdate();
    },
    onItemClick: function onItemClick(index) {
      this.index = index;
      this.$utils.Tools.loadShow();
      this.list = [];
      this.getAttenceDetail(index, this.$route.params.date);

      var _iterator = _createForOfIteratorHelper(this.titles),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (+item.index === +index) {
            this.content = item.title;
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    },
    getAttenceDetail: function getAttenceDetail(index, date) {
      var _this = this;

      this.emptyShow = false;
      this.$api.Attence.getAttenceDetail({
        userSerial: this.userSerial,
        dates: date,
        flag: index
      }).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.list = data; // this.list = [{"date":"2021-07-30","week":"星期五","type":"3","content":"00:00:00","endTime":"00:00:00"},{"date":"2021-07-29","week":"星期四","type":"3","content":"00:00:00","endTime":"00:00:00"}]

          if (_this.list.length === 0) {
            _this.emptyShow = true;
          }

          _this.updateScroll();
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: "title",
        url: ""
      };
      window.history.pushState(state, state.title, state.url);
    }
  },
  created: function created() {
    this.$utils.Tools.loadShow();
    this.userSerial = this.$utils.Store.getItem("userSerial");
    this.index = this.$route.params.index;

    var _iterator2 = _createForOfIteratorHelper(this.titles),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var item = _step2.value;

        if (+item.index === +this.index) {
          this.content = item.title;
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    this.getAttenceDetail(this.$route.params.index, this.$route.params.date);
  },
  mounted: function mounted() {
    this.$utils.Store.setItem("attendDate", this.$route.params.date); // this.pushHistory();
    // window.onpopstate = () => {
    //     this.$router.push('/Attendances');
    // }
  }
};